@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  background-color: #e5e5e5;
  font-family: "Public Sans", sans-serif;
  position: relative;
}

a {
  text-decoration: none;
  display: inline-block;
}

ul {
  margin: 0;

  padding: 0;
  list-style: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

img {
  max-width: 100%;
}

.page-title {
  margin-bottom: 48px;
}

.section-title {
  text-transform: capitalize;
}
.section-title h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #161616;
  padding-bottom: 30px;
}

.mySlide {
  display: none;
}

.mySlideText {
  display: none;
}

.main-bar {
  background: #f7f7f7;
  padding: 40px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .main-bar {
    padding: 10px;
  }
}

.section-padding {
  padding: 40px 0;
}

a.active {
  text-decoration: none;
  color: #000;
  background-color: #ededed;
}
a.active i {
  color: #f70000 !important;
}

body.dark-theme .mode-changer {
  padding: 8px;
  background: #434343;
}
@media (max-width: 991px) {
  body.dark-theme .mode-changer {
    padding: 3px;
  }
}

body.dark-theme {
  background: #161616;
}

body.dark-theme .sidebar {
  background: #2d2d2d;
}
body.dark-theme .sidebar-profile__name {
  color: #f7f7f7;
}
body.dark-theme .sidebar-profile__designation {
  color: #8d8d8d;
}
body.dark-theme .sidebar-menu ul li a span {
  color: #8d8d8d;
}

body.dark-theme .section-title h2 {
  color: #f7f7f7;
}

body.dark-theme .main-bar {
  background: #2d2d2d;
}
body.dark-theme .main-bar .hero-content h1 {
  color: #f7f7f7;
}
body.dark-theme .main-bar .hero-content p {
  color: #a9a9a9;
}
body.dark-theme .main-bar .hero-content__btn a:last-child {
  color: #f7f7f7;
}
body.dark-theme .main-bar .about .section-title h2 {
  color: #f7f7f7;
}
body.dark-theme .main-bar .about-content {
  color: #a9a9a9;
}

body.dark-theme .service-history-card p {
  color: #f7f7f7;
}

body.dark-theme .service-card-content h3 {
  color: #f7f7f7;
}
body.dark-theme .service-card-content p {
  color: #8d8d8d;
}
body.dark-theme .service-card:hover h3 {
  color: #161616;
}
body.dark-theme .service-card:hover .hire-btn {
  color: #bb2e2e;
}

body.dark-theme .card-top h4,
body.dark-theme .card-top h3 {
  color: #f7f7f7;
}
body.dark-theme .card-top p {
  color: #8d8d8d;
}
body.dark-theme .card:hover h4,
body.dark-theme .card:hover h3 {
  color: #161616;
}

body.dark-theme .testimonial .qoute-img path {
  fill: #f7f7f7;
}
body.dark-theme .testimonial__qoute-text {
  color: #a9a9a9;
}
body.dark-theme .testimonial__client-info .client__info-name {
  color: #f7f7f7;
}
body.dark-theme .testimonial__client-info .client__info-designation {
  color: #a9a9a9;
}

body.dark-theme .hire-btn {
  color: #f7f7f7;
}

body.dark-theme .section-title {
  color: #f7f7f7;
}

body.dark-theme .experience-card h2 {
  color: #f7f7f7;
}
body.dark-theme .experience-card h3 {
  color: #8d8d8d;
}
body.dark-theme .experience-card p {
  color: #8d8d8d;
}

body.dark-theme .institution-info h3 {
  color: #f7f7f7;
}
body.dark-theme .institution-info p {
  color: #8d8d8d;
}

body.dark-theme .page-title {
  color: #f7f7f7;
}

body.dark-theme .portfolio-card__title a {
  color: #f7f7f7;
}

body.dark-theme .portfolio-card__btn {
  color: #f7f7f7;
}
body.dark-theme .portfolio-card:hover .portfolio-card__title a {
  color: #161616;
}
body.dark-theme .portfolio-card:hover .portfolio-card__btn {
  color: #f70000;
}

body.dark-theme .main-bar .title-tab-wrapper .tab li a {
  color: #8d8d8d;
  border-bottom: 1px solid transparent;
}
body.dark-theme .main-bar .title-tab-wrapper .tab li a:hover {
  color: #ededed;
  border-bottom: 1px solid #ededed;
}

body.dark-theme .main-bar .title-tab-wrapper .tab li .active {
  color: #ededed;
  border-bottom: 1px solid #ededed;
}

body.dark-theme .more-portfolios-title {
  color: #f7f7f7;
}

body.dark-theme .share-item p {
  color: #8d8d8d;
}

body.dark-theme .contact {
  background: #2d2d2d;
}
body.dark-theme .contact-title h2 {
  color: #f7f7f7;
}
body.dark-theme .contact-title p {
  color: #8d8d8d;
}

.Link_sidebar {
  margin-left: 1px;
  text-decoration: none;
}

.sidebar {
  background-color: #f7f7f7;
  overflow: hidden;
  padding: 16px;
  position: fixed;
  top: 20px;
  bottom: 20px;
}
@media (max-width: 991px) {
  .sidebar {
    left: 0;
    padding: 8px;
  }
}
@media (max-width: 525px) {
  .sidebar {
    padding: 2px;
    left: 0;
  }
}
.sidebar-profile {
  margin: 16px;
  position: relative;
}
@media (max-width: 991px) {
  .sidebar-profile {
    margin: 2px;
  }
}
.sidebar-profile__image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 16px;
}
@media (max-width: 991px) {
  .sidebar-profile__image {
    width: 32px;
    height: 32px;
    margin-top: 20px;
    margin-bottom: 80px;
  }
  .sidebar-profile__image img {
    margin-top: 40px;
  }
}
.sidebar-profile__image img {
  width: 100%;
}
.sidebar-profile__image .mode-changer {
  background: #ededed;
  padding: 10px 11px;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: -10px;
  z-index: 1000;
  border: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sidebar-profile__image .mode-changer {
    padding: 5px;
  }
  .sidebar-profile__image .mode-changer #sun-svg,
  .sidebar-profile__image .mode-changer #moon-svg {
    width: 80%;
    margin: 0px auto;
  }
}
.sidebar-profile__image .mode-changer #sun-svg {
  display: none;
}
.sidebar-profile__name,
.sidebar-profile__designation {
  text-align: center;
}
@media (max-width: 991px) {
  .sidebar-profile__name,
  .sidebar-profile__designation {
    display: none;
  }
}
.sidebar-profile__name {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
}
.sidebar-profile__designation {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 14px;
  color: #707070;
}
.sidebar-profile__social-media {
  margin-bottom: 48px;
}
@media (max-width: 991px) {
  .sidebar-profile__social-media {
    display: none;
  }
}
.sidebar-profile__social-media ul {
  text-align: center;
}
.sidebar-profile__social-media ul li {
  display: inline-block;
  margin: 0 8px;
}
.sidebar-profile__social-media ul li a {
  background: #707070;

  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-profile__social-media ul li a:hover {
  background: #f71900c5;
}
.sidebar-profile__social-media ul li a i {
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  width: 100%;
}
.sidebar-menu {
  margin-bottom: 80px;
}
.sidebar-menu ul li {
  margin-bottom: 13px;
}
@media (max-width: 991px) {
  .sidebar-menu ul li {
    margin: 30px 0;
  }
}
.sidebar-menu ul li:hover a,
.sidebar-menu ul li:active a,
.sidebar-menu ul li:visited a {
  background-color: #ededed;
}
.sidebar-menu ul li:hover a span,
.sidebar-menu ul li:active a span,
.sidebar-menu ul li:visited a span {
  color: #161616;
}
.sidebar-menu ul li:hover a i,
.sidebar-menu ul li:active a i,
.sidebar-menu ul li:visited a i {
  color: #f70000;
}
.sidebar-menu ul li a.active span {
  color: #161616;
}
.sidebar-menu ul li a {
  padding: 6px 0px;
  display: flex;
  align-items: center;
  color: #707070;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.001em;
}
@media (max-width: 991px) {
  .sidebar-menu ul li a {
    justify-content: center;
  }
}
.sidebar-menu ul li a span {
  color: #707070;
}
@media (max-width: 991px) {
  .sidebar-menu ul li a span {
    display: none;
  }
}
.sidebar-menu ul li a i {
  color: #707070;
  width: 16px;
  height: 16px;
  margin: 0px 10px;
}
@media (max-width: 991px) {
  .sidebar-contact {
    display: none;
  }
}
.sidebar-contact p {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.001em;
  color: #707070;
  margin-bottom: 10px;
}
.sidebar-contact i {
  color: #707070;
  margin: 0px 12px;
}

.btn_primary {
  color: #f7f7f7;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #f70000c6;
  border-radius: 5px;
  padding: 10px 35px;
  display: inline-block;
  transition: 0.3s all ease-in;
  width: 170px;
}
.btn_primary:hover {
  background-color: #c61700;
}
@media (max-width: 480px) {
  .btn_primary {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 20px;
  }
}
.btn__hire {
  background-color: #b02b2b;
  border: none;
  color: white;
  width: 150px;
  height: 60px;
  text-align: center;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
}

.click__btn {
  position: relative;
}
.click__btn span img {
  position: absolute;
  top: 9px;
  left: 42px;
  opacity: 0;
  transition: 0.3s all ease-in;
}
.click__btn:hover {
  padding-left: 40px;
  padding-right: 20px;
}
.click__btn:hover img {
  left: 20px;
  opacity: 1;
}

.hire-btn {
  position: relative;
  color: #d11515;
  background-color: transparent;
  text-align: left;
  padding-left: 0;
}
.hire-btn i {
  position: absolute;
  top: 15px;
  left: 56px;
  opacity: 0;
  transition: 0.3s all ease-in;
}
.hire-btn:hover {
  background-color: transparent;
}
.hire-btn:hover i {
  left: 65px;
  opacity: 1;
}

.portfolio-card {
  padding: 32px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.portfolio-card:hover {
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
}
.portfolio-card:hover .portfolio-card__meta {
  background-color: #f7f7f7;
}
@media (max-width: 375px) {
  .portfolio-card {
    padding: 16px;
  }
}
.portfolio-card__feature-image {
  margin-bottom: 16px;
}
.portfolio-card__feature-image a {
  display: block;
}
.portfolio-card__feature-image a img {
  width: 100%;
  height: 250px;
  border-radius: 5px;
}
.portfolio-card__meta {
  margin-bottom: 16px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
}
.portfolio-card__meta p {
  font-size: 14px;
  line-height: 24px;
  color: #707070;
}
.portfolio-card__title a {
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #161616;
  margin-bottom: 40px;
}
.portfolio-card__short-desc {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.001em;
  color: #707070;
  margin-bottom: 16px;
}
.portfolio-card__author {
  font-size: 14px;
  line-height: 20px;
  color: #707070;
  margin-bottom: 40px;
}
.portfolio-card__btn {
  font-size: 14px;
  line-height: 20px;
  color: #0063f7;
  transition: 0.3s all ease-in;
}
.portfolio-card__btn i {
  transition: 0.3s all ease-in;
  opacity: 0;
}
.portfolio-card__btn:hover i {
  opacity: 1;
}

.hero-content h1 {
  font-weight: 700;
  font-size: 70px;
  line-height: 88px;
  letter-spacing: -0.02em;
  color: #161616;
}
@media (max-width: 992px) {
  .hero-content h1 {
    font-size: 55px;
    line-height: 75px;
  }
}
.hero-content h1 span {
  display: block;
}
.hero-content p {
  font-size: 24px;
  line-height: 36px;
  color: #707070;
  margin-bottom: 100px;
}
@media (max-width: 991px) {
  .hero-content p {
    margin-bottom: 45px;
  }
}
.hero-content__btn a:last-child {
  padding: 15px 35px;
  background-color: transparent;
  color: #0063f7;
}
@media (max-width: 991px) {
  .hero-content__btn a:last-child {
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .hero-content__btn {
    margin-bottom: 50px;
  }
}
.hero-img {
  box-sizing: border-box;
  width: 100%;
  height: auto;
}
.hero-img img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 50%;
}

.about .section-title h2 {
  padding-bottom: 15px;
}
.about-img {
  width: 100%;
  height: auto;
}
.about-img img {
  width: 100%;
  height: 100%;
}
.about-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}

.service-card {
  padding: 32px;
  border: 1px solid #e2e2e2;
  transition: 0.3s all ease-in;
}
@media (max-width: 525px) {
  .service-card {
    padding: 15px;
  }
}
.service-card:hover {
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
}
.service-card-icon {
  background-color: #fce7be;
  width: 64px;
  height: 64px;
  position: relative;
  margin-bottom: 16px;
}
.service-card-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.service-card-icon--graphic {
  border-radius: 5px;
}
.service-card-icon--arch {
  background-color: #ffcdcd;
  border-radius: 5px;
}
.service-card-icon--ux {
  background-color: #daf3ff;
  border-radius: 5px;
}
.service-card-content h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #161616;
  padding-bottom: 8px;
}
.service-card-content p {
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  padding-bottom: 15px;
  height: 100px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .service-card-content {
    margin-bottom: 20px;
  }
}

.service-history {
  margin-top: 90px;
  color: #161616;
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add gap between items */
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

@media (max-width: 768px) {
  .service-history {
    justify-content: center;
  }
}

.service-history-card {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-history-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
}

.service-history-card p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.service-history-card h2 {
  border-radius: 50%;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  width: 149px;
  height: 149px;
  margin: 0 auto 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-in-out;
}

.service-history-card h2.complete {
  background: #fce7be;
}

.service-history-card h2.years {
  background: #daf3ff;
}

.service-history-card h2.clients {
  background: #ffcdcd;
}

.service-history-card h2.award {
  background: #8bdb81;
}

@media (max-width: 768px) {
  .service-history-card {
    flex: 0 0 48%; /* Adjust to 2 cards per row */
  }
}

@media (max-width: 425px) {
  .service-history-card {
    flex: 0 0 100%; /* Adjust to 1 card per row */
  }
}

/* Animation for cards appearing */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.service-history-card {
  animation: fadeInUp 0.6s ease-in-out;
  animation-fill-mode: both;
}

/* General card styles */
.card {
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 32px;
  text-align: center;
  transition: 0.3s all ease-in;
}

@media (max-width: 525px) {
  .card {
    padding: 15px;
  }
}

.card:hover {
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
}

.card-top h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #161616;
}

.card-top p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #707070;
  height: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-top h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #161616;
}

.card-top-btn {
  margin: 35px 0;
}

.card-list ul {
  text-align: left;
}

.card-list ul li {
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
  color: #707070;
  padding-bottom: 10px;
}

.card-list ul li span {
  margin-right: 10px;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
}

.card-list ul li span img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-list ul li span.active {
  background-color: #0063f7;
}

.card-list ul li span.inactive {
  background-color: #c6c6c6;
}

@media (max-width: 991px) {
  .card {
    margin-bottom: 15px;
  }
}

.clients-gallery {
  width: 100%;
  height: 345px;
  margin-top: -70px;
}
@media (max-width: 991px) {
  .clients-gallery {
    margin-top: 20px;
  }
}

.testimonial .qoute-img {
  width: 40px;
  height: 32px;
  margin-bottom: 16px;
}
.testimonial__qoute-text {
  padding-left: 40px;
  margin-bottom: 64px;
  font-size: 18px;
  line-height: 20px;
  color: #707070;
  height: 100px;
  overflow-y: auto;
}
.testimonial__client-info {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .testimonial__client-info {
    flex-direction: column;
  }
}
.testimonial__client-info .client {
  display: flex;
}
@media (max-width: 480px) {
  .testimonial__client-info .client {
    justify-content: space-between;
  }
}
.testimonial__client-info .client__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 28px;
}
.testimonial__client-info .client__image img {
  width: 100%;
}
.testimonial__client-info .client__info-name {
  font-size: 18px;
  color: #161616;
  margin-bottom: 4px;
}
.testimonial__client-info .client__info-designation {
  font-size: 16px;
  color: #707070;
}
.testimonial .slider-next-prev-btn {
  cursor: pointer;
  display: flex;
  margin-top: 10px;
}
@media (max-width: 480px) {
  .testimonial .slider-next-prev-btn {
    margin: 20px auto;
  }
}
.testimonial .slider-next-prev-btn .prev p,
.testimonial .slider-next-prev-btn .next p {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #707070;
  transition: 0.3s all ease-in;
  font-size: 12px;
}
.testimonial .slider-next-prev-btn .prev p:hover,
.testimonial .slider-next-prev-btn .next p:hover {
  background-color: #161616;
  color: #fff;
}
.testimonial .slider-next-prev-btn .next {
  margin-left: 48px;
}

.main-bar #load-more-btn {
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #0063f7;
  cursor: pointer;
  display: block;
  margin: 0px auto;
  background-color: transparent;
}

.main-bar .title-tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
@media (max-width: 767px) {
  .main-bar .title-tab-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .main-bar .title-tab-wrapper .tab {
    margin-bottom: 32px;
  }
}
.main-bar .title-tab-wrapper .tab li {
  display: inline-block;
  padding: 0px 12px;
}
@media (max-width: 535px) {
  .main-bar .title-tab-wrapper .tab li {
    display: block;
    text-align: center;
    padding: 8px 0;
  }
}
.main-bar .title-tab-wrapper .tab li a {
  text-decoration: none;
  color: #707070;
  cursor: pointer;
  padding-bottom: 4px;
}
.main-bar .title-tab-wrapper .tab li a:hover {
  text-decoration: none;
  border-bottom: 1px solid #161616;
  color: #161616;
}
.main-bar .title-tab-wrapper .tab li .active {
  background-color: transparent;
  border-bottom: 1px solid #161616;
  color: #161616;
}
.main-bar #portfolio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 45px;
  margin-bottom: 64px;
}
@media (max-width: 767px) {
  .main-bar #portfolio {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.main-bar #portfolioTabImages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 45px;
}
@media (max-width: 767px) {
  .main-bar #portfolioTabImages {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.main-bar #portfolioTabWebDesign {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 45px;
}
@media (max-width: 767px) {
  .main-bar #portfolioTabWebDesign {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.main-bar #portfolioTabPotrait {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 45px;
}
@media (max-width: 767px) {
  .main-bar #portfolioTabPotrait {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.main-bar #portfolioTabMobileApp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 45px;
}
@media (max-width: 767px) {
  .main-bar #portfolioTabMobileApp {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
.main-bar #load-more-btn {
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #0063f7;
  cursor: pointer;
  display: block;
  margin: 0px auto;
  background-color: transparent;
  margin-top: 40px;
}

.more-portfolios-title {
  margin-bottom: 48px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #161616;
}

.contact {
  background-color: #f7f7f7;
}
.contact-title {
  width: 400px;
}
.contact-title h2 {
  padding: 0 40px 10px 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #161616;
}
.contact-title p {
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  padding-bottom: 40px;
}
.contact-map {
  width: 100%;
  height: 450px;
}
@media (max-width: 991px) {
  .contact-map {
    height: 450px;
  }
}
.contact-map img {
  width: 100%;
  height: 100%;
}
.contact-form {
  width: 100%;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px 16px;
  background-color: transparent;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: "Public Sans", sans-serif;
}
.contact-form input::-moz-placeholder,
.contact-form textarea::-moz-placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #8d8d8d;
}
.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #8d8d8d;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #8d8d8d;
}
.contact-form-btn {
  margin-top: 10px;
}
.contact-form-btn .btn_primary {
  padding: 14px 25px;
}
@media (max-width: 991px) {
  .contact-form {
    margin-top: 50px;
    width: 80%;
  }
}
@media (max-width: 767px) {
  .contact-form {
    margin-top: 50px;
    width: 100%;
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  outline: none;
}

input:focus,
textarea:focus {
  border: 1px solid #66a1fa;
}

.education-top {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .education-top {
    flex-direction: column;
    gap: 20px;
  }
}
.education .experience-card {
  display: flex;
  padding: 8px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 12px;
}
.education .experience-card__left {
  margin-right: 8px;
}
.education .experience-card__left img {
  padding: 12px 8px;
}
.education .experience-card__right h2 {
  font-size: 18px;
  margin-bottom: 8px;
}
.education .experience-card__right h3 {
  font-size: 14px;
  margin-bottom: 16px;
  color: #707070;
}
.education .experience-card__right p {
  font-size: 14px;
  color: #707070;
}

.institution {
  border-left: 1px solid #e2e2e2;
  padding: 0 15px;
}
@media (max-width: 480px) {
  .institution {
    margin-top: 20px;
  }
}
.institution-info {
  position: relative;
  margin-bottom: 20px;
}
.institution-info::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #707070;
  position: absolute;
  left: -23px;
  top: 3px;
}
.institution-info h3 {
  padding: 0 10px 10px 0;
}
.institution-info p.name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}
.institution-info p.year {
  font-size: 16px;
  line-height: 24px;
  color: #c6c6c6;
  padding-bottom: 15px;
}
.institution-info p.details {
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}

/*  */
.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem;
}

.skillsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
}

.skill--box {
  background: #635c5c;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 160px;
  height: 160px;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  transition: 300ms ease-in-out;
}

.skill--box:hover {
  transform: scale(1.15);
}

.skill--box > img {
  height: 50px;
  pointer-events: none;
}

.skill--box h3 {
  font-family: Big Shoulders Text;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  margin-top: 1rem;
}

@media (min-width: 992px) and (max-width: 1380px) {
  .skills {
    padding: 2rem 1rem 1rem 1rem;
  }
  .skillsContainer {
    padding: 1.5rem;
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .skills {
    padding: 1rem;
    min-height: 100%;
  }
  .skillsContainer {
    padding: 1rem;
    margin: 1rem 0;
  }
  .skillsHeader h2 {
    font-size: 3.2rem;
  }
  .skill--box {
    width: 150px;
    height: 150px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    height: 45px;
  }

  .skill--box h3 {
    font-size: 20px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .skills {
    padding: 0.5rem;
  }
  .skillsContainer {
    padding: 0.5rem;
  }
  .skillsHeader h2 {
    font-size: 3rem;
  }

  .skill--scroll {
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .skill--box {
    width: 135px;
    height: 135px;
    margin: 1.2rem;
    padding: 2rem 1rem;
  }

  .skill--box > img {
    height: 40px;
  }

  .skillsHeader h2 {
    font-size: 2.5rem;
  }

  .skill--box h3 {
    font-size: 18px;
    margin-top: 1rem;
  }
}
